import * as XLSX from 'xlsx';
import * as xmlbuilder from 'xmlbuilder';

const handleDownloadTemplate = () => {
  const wb = XLSX.utils.book_new();
  const ws_data =[['Supplier Name','Colour No','Colour Name','PIM Colour','DIFF ID 1','DIFF ID 2']]
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  
  function s2ab(s) { 
      const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      let view = new Uint8Array(buf);  //create uint8array as viewer
      for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; 
      return buf;    
  }

  return s2ab(wbout)
  
}

function allowedFile(filename) {
  return filename.split('.').pop().toLowerCase() === 'xlsx';
}


function convert(arrayBuffer) {
  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1});

    const root = xmlbuilder.create('STEP-ProductInformation', { encoding: 'UTF-8', headless: true });
    root.att('WorkspaceID', 'Main');
    root.att('ContextID', 'International');

    const Products = root.ele('Products');
    
    const arrList = data[0];

    
    const finalListValue = []
    
     
    for (let i = 1; i < data.length; i++) {
      const dictFinal = {}
      for (let j=0; j < arrList.length; j++){

        dictFinal[arrList[j]] = data[i][j];
        

      }
      finalListValue.push(dictFinal)
      
    
  }

    
    const Product1 = Products.ele('Product', {ParentID:"BuySideRoot", UserTypeID:"BuySideItem"});
    const Product2 = Product1.ele('Values');
    Product2.ele('Value',{AttributeID:"att_fields"}).txt(JSON.stringify(finalListValue));

    const xmlString = root.end({ pretty: true });

    return { success: true, xmlString };
  
}

export { convert, allowedFile ,handleDownloadTemplate};

