import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { convert, allowedFile,handleDownloadTemplate } from "./excelToXml";
import { Link } from 'react-router-dom';
import { IoIosInformationCircleOutline, IoMdDownload } from 'react-icons/io';


function Upload() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setErrorMessage('Please select a file.');
      return;
    }

    if (!allowedFile(file.name)) {
      setErrorMessage('Invalid file format. Please upload a .xlsx file.');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const result = convert(event.target.result);
      if (result.success) {
        const blob = new Blob([result.xmlString], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'STEP_SupplierColourHierarchy.xml');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setErrorMessage(null);
      } else {
        setErrorMessage(result.error);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const downloadTemplate=()=>{
    const blob = new Blob([handleDownloadTemplate()], {type:"application/octet-stream"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'template.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

  }
 

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md="6">
          <h1 className="text-center mb-4">Excel to XML Converter</h1>
          <Form onSubmit={handleSubmit} className="p-4 bg-light rounded shadow">
            <Row className="d-flex flex-sm-row justify-content-between align-items-center">
              <Col md="auto">
                <Form.Group controlId="formFile">
                  <Form.Label>Upload Excel File (.xlsx)</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Control type="file" accept=".xlsx" onChange={handleFileChange} />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Convert to XML
            </Button>
          </Form>
          {errorMessage && (
            <Alert className="mt-3" variant="danger">
              {errorMessage}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Upload;